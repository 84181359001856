<script>
import GetTrackingEvents from '@/api/trackingEvents/getTrackingEvents';
import PostTrackingEvents from '@/api/trackingEvents/postTrackingEvents';
import Modal from '@/components/Modal';
import MyCasesTile from '@/components/MyCasesTile';
import WelcomeToFastStart from '@/components/messaging/WelcomeToFastStart';
import { mapGetters } from 'vuex';
import Loader from '@/components/Loader.vue';
import loadAllCasesForUser from '@/functions/loadAllCasesForUser';

export default {
  name: 'Dashboard',
  components: { Loader, Modal, WelcomeToFastStart, MyCasesTile },
  data() {
    return {
      tenancy: this.$tenancy,
      sortMoveFastStartClientLeaflet: '/fast-start-for-clients.pdf',
      loading: true,
      videoId: process.env.VUE_APP_VIDEO_ID,
      cssProps: {
        backgroundImage: `url(${require('@/assets/sortmove-for-sale-2.png')})`,
        backgroundSize: '330px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right top',
        minHeight: '330px',
        overflow: 'visible',
      },
      cssPropsInner: {
        backgroundImage: `url(${require(`@/assets/${this.$i18n.t('dashboard.forSaleLogo')}`)})`, // eslint-disable-line import/no-dynamic-require
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left top',
        overflow: 'visible',
        backgroundSize: 'contain',
      },
      showClientWelcomeModal: false,
      confirmationEvent: { clientWelcome: 'client_welcome' },
    };
  },
  computed: {
    ...mapGetters('auth', ['cognitoUserId']),
    link() {
      switch (this.productCode) {
        case 'To': // Standalone Transfer Of Equity
          return 'https://www.youtube.com/embed/KOLCRl96aHk';
        case 'R':
        case 'FR': // Remortgage
          return 'https://www.youtube.com/embed/KeKSqOmBpbo';
        default:
          // Conveyancing
          return 'https://www.youtube.com/embed/ytJxMGhzQfM';
      }
    },
    title() {
      switch (this.productCode) {
        case 'S':
          return 'Sale';
        case 'P':
          return 'Purchase';
        case 'To':
          return 'Standalone Transfer of Equity';
        case 'R':
        case 'FR':
          return 'Remortgage';
        default:
          return 'Unknown';
      }
    },
  },
  methods: {
    async checkClientWelcomeModalStatus() {
      const confirmationEvents = Object.values(this.confirmationEvent);
      await GetTrackingEvents(this.cognitoUserId, confirmationEvents)
        .then((response) => {
          let count = response.data.count;
          if (count > 0) {
            this.showClientWelcomeModal = false;
          } else {
            this.showClientWelcomeModal = false;
          }
        })
        .catch((err) => console.debug(err));
    },
    async closeClientWelcomeModal() {
      let trackingEvent = {
        product_slug: 'sale',
        entity_id: this.cognitoUserId,
        event_slug: this.confirmationEvent.clientWelcome,
        metadata: '',
      };
      await PostTrackingEvents(trackingEvent)
        .then(() => {
          this.$gtag.event('Toggle', {
            event_category: 'User',
            event_label: 'Preview Welcome Modal',
          });
          this.showClientWelcomeModal = false;
        })
        .catch((err) => console.debug(err));
    },
    helpCentre: function () {
      this.$router.push('/help-centre');
    },
  },
  async mounted() {
    await this.checkClientWelcomeModalStatus();
    // Load the case type related to the current user so that the correct video links are used
    const res = await loadAllCasesForUser(this.cognitoUserId).catch((err) => {
      this.showError = 'case(s)';
      console.error(err);
      return {};
    });
    if (res?.results?.length) {
      this.showDelayInfo = false;
      this.productCode = res.results[0]['product_code'];
    }
    this.loading = false;
  },
};
</script>

<template>
  <div class="mb-4">
    <div class="row">
      <my-cases-tile />
      <div class="col-12 col-lg-4" @click="helpCentre">
        <div class="card cursor-pointer bg-light" style="min-height: 334px">
          <div class="card-body text-center">
            <i class="icon-help icon-2x text-warning-400 border-warning-400 border-3 rounded-round p-2 mb-3 mt-1"></i>
            <h5 class="card-title">Helpful Guides</h5>
            <p>
              We have put together a number of useful videos and downloadable guides to support you through the
              {{ $t('site.processName') }} process. From a general overview of what
              {{ $t('site.processName') }} includes, a step by step process guide or assistance with your ID
              verification, we have guides to assist you at each step of the way.
            </p>
          </div>
        </div>
      </div>

      <div class="col-lg-3 d-none d-sm-none d-md-none d-lg-block d-xl-block">
        <div :style="cssProps"></div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-6">
        <div class="card bg-light fs-mh-465px sl-mh-400px" style="min-height: 465px">
          <div class="card-body text-center">
            <div class="row">
              <div
                :style="cssPropsInner"
                class="col-lg-4 col-md-4 col-sm-6 fs-welcome-image sl-welcome-image align-self-center"
              ></div>
              <div class="col-lg-8 col-md-8 col-sm-6 font-size-lg align-self-center">
                <h3 class="title m">
                  {{ $t('dashboard.welcomeHeading') }}
                </h3>
                <p>
                  {{ $t('dashboard.introText') }}
                </p>
                <p>
                  For further information on the process, please head to our
                  <span class="text-nowrap">'Helpful Guides'</span> section.
                </p>
                <p>
                  <strong>
                    If you have any technical questions relating to your {{ $t('site.processName') }} process, please
                    contact our friendly team by clicking the 'Require Assistance' link at the top of your dashboard.
                  </strong>
                </p>
                <p>{{ $t('site.companyTitle') }} - {{ $t('dashboard.furtherInfoText') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="card bg-light">
          <div class="card-body">
            <iframe
              :src="`https://www.youtube.com/embed/${$t('site.video')}`"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              frameborder="0"
              height="418"
              width="100%"
            ></iframe>
            <p class="d-block d-sm-block d-md-none">
              <a :href="`https://youtu.be/${$t('site.video')}`">Please click here to view the video</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <loader v-if="loading" class="pb-2"><span>Please wait, loading video</span></loader>
    <section v-else>
      <div v-if="tenancy === 'sortlegal'" class="row">
        <div class="col-12 col-sm-12 col-md-6">
          <div class="card bg-light">
            <div class="card-header header-elements-inline">
              <h3 class="card-title">
                A step-by-step guide on how to use the {{ $t('site.companyTitle') }} {{ title }} Portal
              </h3>
            </div>
            <div class="card-body">
              <p>
                This short video guide explains the process of completing our {{ title }} Onboarding Portal. It outlines
                the information we need from you prior to starting work on your {{ title }}.
              </p>
              <iframe
                :src="link"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                frameborder="0"
                height="360"
                width="100%"
              ></iframe>
              <p class="d-block d-sm-block d-md-none">
                <a href="https://youtu.be/KeKSqOmBpbo">Please click here to view the video</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div v-if="tenancy === 'sortmove'" class="row">
      <div class="col-12 col-sm-12 col-md-6">
        <div class="card bg-light">
          <div class="card-header header-elements-inline">
            <h3 class="card-title">{{ $t('helpCentre.processOverviewHeader') }}</h3>
          </div>
          <div class="card-body">
            <p>
              Please familiarise yourself on what our {{ $t('site.processName') }} process is all about by watching our
              short video bellow. Or alternatively download our free {{ $t('site.processName') }} leaflet to help you
              get started.
            </p>
            <a
              :href="this.sortMoveFastStartClientLeaflet"
              class="btn btn-info mb-2"
              download="Sort Move Fast-Start Client Leaflet"
            >
              Download {{ $t('site.companyTitle') }} {{ $t('site.processName') | capitalize }} Client Leaflet
            </a>
            <iframe
              :src="`https://www.youtube.com/embed/${videoId}`"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              frameborder="0"
              height="418"
              width="100%"
            ></iframe>
            <p class="d-block d-sm-block d-md-none">
              <a :href="`https://youtu.be/${videoId}`">Please click here to view the video</a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <modal
      v-if="showClientWelcomeModal"
      :cognitoUserId="this.cognitoUserId"
      :fullModalWidth="false"
      :show-buttons="true"
      closeButtonText="Close"
      @proceed="closeClientWelcomeModal"
    >
      <welcome-to-fast-start />
    </modal>
  </div>
</template>
