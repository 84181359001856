import getPersonsByCognitoId from '@/api/persons/getPersonsByCognitoId';
import getPurchasesByClientIds from '@/api/purchases/getPurchasesByClientIds';
import getRemortgagesByClientIds from '@/api/remortgages/getRemortgagesByClientIds';
import getSalesByClientIds from '@/api/sales/getSalesByClientIds';

export const DEFAULT_CASES = {
  count: 0,
  errors: [],
  results: [],
};

const mergeCases = (...results) => {
  const cases = { ...DEFAULT_CASES };
  for (const result of results) {
    cases.count += result.count;
    cases.results = [...cases.results, ...result.results];
  }
  cases.results = cases.results.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  return cases;
};

const loadPurchases = async (personIds) => {
  const purchases = await getPurchasesByClientIds(personIds).catch(() => ({
    data: { ...DEFAULT_CASES, errors: ['Error loading purchase case(s)'] },
  }));
  return purchases.data;
};
const loadRemortgages = async (personIds) => {
  const remortgages = await getRemortgagesByClientIds(personIds).catch(() => ({
    data: { ...DEFAULT_CASES, errors: ['Error loading remortgage case(s)'] },
  }));
  return remortgages.data;
};
const loadSales = async (personIds) => {
  const sales = await getSalesByClientIds(personIds).catch(() => ({
    data: { ...DEFAULT_CASES, errors: ['Error loading sale case(s)'] },
  }));
  return sales.data;
};

const loadAllCasesForUser = async (cognitoUserId) => {
  const errors = [];

  // Get all the person ids to fetch cases for
  const persons = await getPersonsByCognitoId(cognitoUserId);
  const personIds = persons?.data?.results?.map(({ person_id }) => person_id) ?? [];

  const results = personIds.length
    ? await Promise.all([loadPurchases(personIds), loadRemortgages(personIds), loadSales(personIds)])
    : [];
  const cases = mergeCases(...results);

  if (cases?.errors?.length) {
    throw new Error(errors);
  }
  return cases;
};

export default loadAllCasesForUser;
