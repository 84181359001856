var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('div',{staticClass:"card-body"},[_c('loader',[_vm._v("Loading "+_vm._s(_vm.product)+"...")])],1):_c('div',[_c('router-link',{staticClass:"text-default",attrs:{"to":{
      name: 'ViewEntity',
      params: {
        entityType: _vm.product,
        entityId: _vm.smCase[(_vm.product + "_id")],
        thoroughfare: _vm.address.thoroughfare,
        postal_code: _vm.address.postal_code,
      },
    }}},[_c('div',{staticClass:"row"},[_c('h6',{staticClass:"text-capitalize col-12 col-sm-9 col-lg-12 col-xl-9"},[_c('i',{staticClass:"icon-enter mr-2 d-inline d-sm-none"}),_vm._v(_vm._s(this.smCase.standalone_toe ? 'Transfer Of Equity for' : (_vm.product + " of"))+" "+_vm._s(_vm.address.premise)+" "+_vm._s(_vm.address.thoroughfare)+" ("+_vm._s(this.smCase.sort_refer_id)+") ")]),_c('button',{staticClass:"\n          bg-info bg-primary\n          btn btn-primary btn-sm\n          p-1\n          d-none d-sm-block d-lg-none d-xl-block\n          col-0 col-sm-3 col-lg-0 col-xl-3\n        ",staticStyle:{"max-height":"2rem"}},[_c('i',{staticClass:"icon-enter3"}),_c('span',{staticClass:"d-md-inline-block d-lg-none d-xl-inline-block ml-2"},[_vm._v("Open")])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }