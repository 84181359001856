<script>
import Loader from '@/components/Loader';
import getBuilding from '../api/buildings/getBuilding';

export default {
  name: 'MyCasesTilePurchase',
  components: { Loader },
  props: {
    smCase: {
      type: Object,
      required: true,
    },
    product: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      showError: false,
      address: false,
    };
  },
  async mounted() {
    // Temporarily we have a difference between remo and sale/purchase due to backend @TODO: Remove when uniform
    if (this.smCase.building_id !== undefined) {
      this.address = await getBuilding(this.smCase.building_id).then((res) => res.data.address);
      this.loading = false;
      return;
    }

    this.address = this.smCase.address;
    this.loading = false;
    return;
  },
};
</script>

<template>
  <div v-if="loading" class="card-body">
    <loader>Loading {{ product }}...</loader>
  </div>
  <div v-else>
    <router-link
      class="text-default"
      :to="{
        name: 'ViewEntity',
        params: {
          entityType: product,
          entityId: smCase[`${product}_id`],
          thoroughfare: address.thoroughfare,
          postal_code: address.postal_code,
        },
      }"
    >
      <div class="row">
        <h6 class="text-capitalize col-12 col-sm-9 col-lg-12 col-xl-9">
          <i class="icon-enter mr-2 d-inline d-sm-none"></i
          >{{ this.smCase.standalone_toe ? 'Transfer Of Equity for' : `${product} of` }} {{ address.premise }}
          {{ address.thoroughfare }} ({{ this.smCase.sort_refer_id }})
        </h6>
        <button
          class="
            bg-info bg-primary
            btn btn-primary btn-sm
            p-1
            d-none d-sm-block d-lg-none d-xl-block
            col-0 col-sm-3 col-lg-0 col-xl-3
          "
          style="max-height: 2rem"
        >
          <i class="icon-enter3"></i>
          <span class="d-md-inline-block d-lg-none d-xl-inline-block ml-2">Open</span>
        </button>
      </div>
    </router-link>
  </div>
</template>
