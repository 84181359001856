<script>
export default {
  name: 'WelcomeToFastStart',
  data() {
    return {
      backgroundImg: {
        // eslint-disable-next-line import/no-dynamic-require
        backgroundImage: `linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url(${require(`@/assets/${this.$i18n.t(
          'welcomeToFastStart.forSaleBackground',
        )}`)})`, // eslint-disable-line import/no-dynamic-require
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
      },
      logoImg: {
        backgroundImage: `url(${require(`@/assets/${this.$i18n.t('site.logo')}`)})`, // eslint-disable-line import/no-dynamic-require
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        minHeight: '5rem',
      },
    };
  },
};
</script>

<template>
  <div id="page-content" :style="backgroundImg">
    <h2>Welcome to {{ $t('site.processName') }}</h2>

    <div class="row my-4">
      <div class="col-12">
        <p class="my-2 heading-color"><strong> Before you get started </strong></p>

        <p>
          Before you start filling in your online forms, please take a look at our 'Helpful Guides' section which
          includes a number of handy videos and downloadable PDFs about the benefits of this process and a step by step
          guide of how to complete your information.
        </p>
      </div>
    </div>

    <div class="row my-4">
      <div class="col-12">
        <p class="my-2 heading-color">
          <strong> Why use {{ $t('site.processName') }}? </strong>
        </p>

        <p>
          {{ $t('welcomeToFastStart.whyUseContent') }}
        </p>
      </div>
    </div>

    <div class="row my-4">
      <div class="col-12">
        <p class="my-2 heading-color"><strong> For Help/Assistance </strong></p>

        <p>
          If you have any technical questions relating to your {{ $t('site.processName') }} process, please contact our
          friendly team by clicking the 'Require Assistance' link at the top of your dashboard.
        </p>

        <p>
          If you have any questions relating to your case or the Conveyancing process, please speak with your
          {{ $t('site.referrer') }}.
        </p>
      </div>
    </div>

    <div :style="logoImg"></div>
  </div>
</template>

<style scoped>
#page-content {
  height: 100%;
  font-size: 1rem;
  text-align: center;
}

.modal-container {
  width: 100% !important;
}

#page-content th {
  vertical-align: top;
}

.form-check-input {
  font-size: 1rem;
}

.btn-round {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 35px;
  line-height: 1.33;
  pointer-events: none;
  background-color: white;
}
</style>
