<script>
import Loader from '@/components/Loader.vue';
import MyCasesProductTile from '@/components/MyCasesProductTile';
import { mapGetters } from 'vuex';
import loadAllCasesForUser from '@/functions/loadAllCasesForUser';

export default {
  name: 'MyCasesTile',
  components: { MyCasesProductTile, Loader },
  computed: mapGetters('auth', ['cognitoUserId']),
  data() {
    return {
      loading: true,
      persons: [],
      cases: {},
      showError: false,
      cssProps: {
        backgroundImage: `url(${require('@/assets/sortmove-bricky.png')})`,
        backgroundSize: '300px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center bottom',
        paddingBottom: '320px',
      },
    };
  },
  methods: {
    viewCases: function () {
      this.$router.push('/my-cases');
    },
  },
  async mounted() {
    const res = await loadAllCasesForUser(this.cognitoUserId).catch((err) => {
      this.showError = 'case(s)';
      console.error(err);
      return {};
    });
    this.cases = res.results;
    this.loading = false;
  },
};
</script>

<template>
  <div class="col-lg-5 col-12 col-md-12">
    <div class="card cursor-pointer" style="min-height: 334px">
      <div class="card-body text-center">
        <i
          class="icon-files-empty icon-2x text-success-400 border-success-400 border-3 rounded-round p-2 mb-3 mt-1"
          @click="viewCases"
        ></i>
        <h5 class="card-title" @click="viewCases">My cases</h5>
        <loader v-if="loading">Loading your cases...</loader>
        <div v-else-if="cases.length !== 0">
          <ul class="list-group list-group-bordered">
            <li
              v-for="(smCase, index) in cases"
              :key="index"
              class="list-group-item d-flex justify-content-between align-items-left"
            >
              <my-cases-product-tile
                v-if="smCase.sale_id"
                :sm-case="smCase"
                class="w-100"
                product="sale"
              ></my-cases-product-tile>
              <my-cases-product-tile
                v-if="smCase.purchase_id"
                :sm-case="smCase"
                class="w-100"
                product="purchase"
              ></my-cases-product-tile>
              <my-cases-product-tile
                v-if="smCase.remortgage_id"
                :sm-case="smCase"
                class="w-100"
                product="remortgage"
              ></my-cases-product-tile>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
