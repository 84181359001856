import axios from 'axios';

/**
 *
 * @param personIds - an array of GUIDs
 * @returns {Promise<AxiosResponse<T>>}
 */
export default (personIds) => {
  let queryString = '';
  // Since we use identical query key values (as per API Gateway) we can't rely on Axios params
  personIds.forEach((personId) => {
    queryString += queryString.length === 0 ? `?personIds=${personId}` : `&personIds=${personId}`;
  });
  return axios.get(`${process.env.VUE_APP_API_ENDPOINT}/purchases/find-by-client-ids${queryString}`);
};
